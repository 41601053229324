<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-card title="Generate NPP">
          <b-table :items="items" :fields="fields" responsive striped>
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(employee)="data">
              {{ data.item.employee }} / {{ data.item.name }}
            </template>
            <template #cell(daily_money)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.daily_money | currency }}
              </span>
            </template>
            <template #cell(total_accomodation)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_accomodation | currency }}
              </span>
            </template>
            <template #cell(total_real_expense)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_real_expense | currency }}
              </span>
            </template>
            <template #cell(total_transportation)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_transportation | currency }}
              </span>
            </template>
            <template #cell(total_anggaran)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_anggaran | currency }}
              </span>
            </template>
          </b-table>
          <b-card-footer align="right">
            <b-button variant="secondary" @click="goBack()">Batal</b-button>
            <b-button
              v-if="items.length"
              variant="primary"
              class="ml-1"
              @click="save()"
              :disabled="isLoading"
            >
              <div v-if="isLoading">
                Simpan <feather-icon icon="LoaderIcon" />
              </div>
              <div v-else>Simpan</div>
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BTable, BCardFooter, BButton, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BCardFooter,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        'no',
        { key: 'employee', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'Nomor SPPD' },
        { key: 'name_subdirektorat', label: 'Sub Direktorat' },
        { key: 'name_regional', label: 'Regional' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_real_expense', label: 'Biaya Pengeluaran Rill' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_anggaran', label: 'Total Biaya' },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get('/npps/preview').then(res => {
        this.items = res.data.data
      })
    },
    goBack() {
      this.$router.back()
    },
    save() {
      this.isLoading = true
      axios.post('/npps').then((response) => {

        const responseMessage = response.message; // Mengambil data dari respons
        console.log('Response Data:', response); // Log untuk melihat data

        this.isLoading = false
        this.$router.push({
          name: 'manajemen-npp',
          query: {
            event: 'success',
            title: 'Berhasil',
            text: responseMessage ?? 'NPP berhasil digenerate',
          },
        })
      })
      .catch(error => {
        this.isLoading = false
        console.error(error)
        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style></style>
