var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","show":_vm.isLoading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h6',{staticClass:"mb-25"},[_vm._v(" Nama Role ")]),_c('p',[_vm._v(_vm._s(_vm.model.name))])]),_c('hr'),_c('b-col',{attrs:{"md":"12"}},[_c('h6',{staticClass:"mb-25"},[_vm._v(" Pilih Regional ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{ref:"row",staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"9"}},[_c('ValidationProvider',{attrs:{"name":"regional"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('multiselect',{staticClass:"single-select",attrs:{"name":"idwilayah","label":"nmwilayah","track-by":"idwilayah","placeholder":"Cari regional","options":_vm.regionalList,"max-height":600},on:{"input":function($event){return _vm.getKantor(_vm.selectedRegional.idwilayah)}},model:{value:(_vm.selectedRegional),callback:function ($$v) {_vm.selectedRegional=$$v},expression:"selectedRegional"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Maaf regional tidak ditemukan")])]),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h6',{staticClass:"mb-25"},[_vm._v(" Pilih Kantor ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{ref:"row",staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"9"}},[_c('ValidationProvider',{attrs:{"name":"kantor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('multiselect',{staticClass:"single-select",attrs:{"name":"nopend","label":"namaktr","track-by":"nopend","placeholder":"Cari kantor","options":_vm.kantorList,"loading":_vm.isLoadingKantor,"max-height":600},on:{"input":function($event){return _vm.getPegawai(_vm.selectedKantor.nopend)}},model:{value:(_vm.selectedKantor),callback:function ($$v) {_vm.selectedKantor=$$v},expression:"selectedKantor"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Maaf kantor tidak ditemukan")])]),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('h6',{staticClass:"mb-25"},[_vm._v(" NIPPOS "),_c('span',{staticClass:"alert-required"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{ref:"row",staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"9"}},[_c('ValidationProvider',{attrs:{"name":"nippos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('multiselect',{attrs:{"label":"nippos","name":"pegawaiList","track-by":"nippos","placeholder":"Cari nippos","options":_vm.pegawaiList,"multiple":true,"loading":_vm.isLoadingPegawai,"searchable":true,"clear-on-select":true,"close-on-select":true,"custom-label":_vm.customLabelNIP,"max-height":600},model:{value:(_vm.model.nippos),callback:function ($$v) {_vm.$set(_vm.model, "nippos", $$v)},expression:"model.nippos"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Maaf nippos tidak ditemukan")])]),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)],1),_c('b-card-footer',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'manajemen-role' }}},[_vm._v(" Batal ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }