<template>
    <b-row>
      <b-col cols="12" v-if="can('detail/pengelolaan-anggaran/summary')">
          <b-card title="Detail Pengelolaan Anggaran Summary">
            <b-row class="pb-2">
              <b-col md="4" xs="12">
                <b-form @submit.prevent="handleSearch">
                  <b-input-group size="8">
                    <b-form-input
                      id="filterInput"
                      v-model="search"
                      type="search"
                      :placeholder="'Cari disini...'"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!search" @click="search = ''">
                        Hapus
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </b-col>
            </b-row>
            <b-table
              ref="tableFe"
              striped
              hover
              responsive
              show-empty
              class="position-relative"
              :perPage="perPage"
              :currentPage="currentPage"
              :totalRows="totalRows"
              :fields="fields"
              :items="getItems"
              :sort-by.sync="sortBy"
              @changePerPage="changePerPage"
              @changeCurrentPage="changeCurrentPage"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <div>Loading data, please wait...</div>
                </div>
              </template>
              <template #empty>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #emptyfiltered>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #cell(no)="data">
                {{ (currentPage - 1) * perPage + (data.index + 1) }}
              </template>
              <template #cell(anggaran)="data">
                <span class="text-capitalize">{{
                  data.item.anggaran | currency
                }}</span>
              </template>
              <template #cell(type)="data">
                <span class="text-capitalize">{{
                  data.item.type
                }}</span>
              </template>
              <!-- A virtual column -->
              <template #cell(Aksi)="data">
                <div style="min-width: 100px">
                  <b-button
                    v-if="can('detail/pengelolaan-anggaran/summary/'+data.item.type)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-b-popover.hover.bottom="'Detail'"
                    size="sm"
                    class="btn-icon float-sm-left"
                    @click="gotoDetailPage(data.item.id, data.item.type)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="can('edit/pengelolaan-anggaran/'+data.item.type)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-popover.hover.bottom="'Ubah'"
                    size="sm"
                    class="btn-icon float-sm-left mr-1"
                    @click="gotoEditPage(data.item.id, data.item.type)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-if="can('delete/pengelolaan-anggaran/'+data.item.type)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    v-b-popover.hover.bottom="'Hapus'"
                    size="sm"
                    class="btn-icon float-sm-left mr-1"
                    @click="confirmDelete(data.item.id, data.item.type)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
            <span v-if="totalRows == 1" style="margin: 1rem"></span>
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <b-form-group
                  class="mb-0"
                >
                  <label class="d-inline-block text-sm-left mr-50">Per Page</label>
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-50"
                  />
                </b-form-group>
                <!-- pagination -->
                <div>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  />
                </div>
            </b-card-body>
            <b-card-footer>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mr-1 float-right"
                    variant="outline-secondary" @click="goBack">
                    Kembali
                </b-button>
            </b-card-footer>
          </b-card>
        </b-col>
        <b-col v-else>
          <b-card>
            <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
              <div class="w-100 text-center">
                <h2 class="mb-1">
                    Tidak Memiliki Akses
                </h2>
                <p class="mb-2">
                    Anda tidak memiliki akses pada halaman ini
                </p>
              </div>
            </div>
          </b-card>
        </b-col>
    </b-row>
  </template>
  
  <style>
    .width160px {
      width: 160px !important;
      background-color: "red !important";
    }
    </style>
  
  <script>
    import {
      BRow,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCard,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BDropdownDivider,
      BCol,
      BModal,
      VBModal,
      BForm,
      BFormRadioGroup,
      BFormTextarea,
      VBPopover,
      BFormCheckbox,
    } from 'bootstrap-vue';
    import vSelect from "vue-select";
    import BCardCode from "@core/components/b-card-code";
    import axios from "@axios";
    import Ripple from "vue-ripple-directive";
  
    export default {
      name: "SummaryList",
      components: {
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        vSelect,
        BModal,
        BCardCode,
        BForm,
        BFormRadioGroup,
        BFormTextarea,
        BFormCheckbox,
      },
      directives: {
        Ripple,
        "b-popover": VBPopover
      },
      data() {
        return  {
          config: {
            api: "/budgets",
          },
          search: '',
          perPage: 10,
          currentPage: 1,
          totalRows: 10,
          sortBy: 'id',
          pageOptions: [1, 10, 25, 50, 100],
          fields: [
            "no",
            { key: "anggaran", label: "Total" },
            { key: "name_subdirektorat", label: "Sub Direktorat" },
            { key: "name_regional", label: "Regional" },
            { key: "start_date", label: "Periode Awal", thStyle: 'max-width:300px' },
            { key: "end_date", label: "Periode Akhir", thStyle: 'max-width:300px' },
            { key: 'type', label: 'Type Anggaran'},
            { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1'}
          ],
          items: [],
        }
      },
      watch: {
        search: function () {
          this.$refs.tableFe.refresh()
        },
      },
      mounted() {
        let _ = this;
        if (_.$route.params) {
          if (_.$route.params.event) {
            _.makeToast(
              _.$route.params.event,
              _.$route.params.title,
              _.$route.params.text
            );
          }
        }
      },
      methods: {
        goBack(){
            window.history.back();
        },
        gotoDetailPage(id, types) {
          let _ = this;
          _.$router.push("/pengelolaan-anggaran/"+ types +"/" + id + "/detail");
        },
        gotoEditPage(id, types) {
          let _ = this;
          _.$router.push("/pengelolaan-anggaran/"+ types +"/" + id + "/edit");
        },
        confirmDelete(id, types) {
          this.$swal({
            title: 'Apakah Anda Yakin',
            text: 'untuk menghapus data?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              axios
                .delete(`${this.config.api}/${id}`)
                .then(response => {
                  this.$swal({
                    icon: 'success',
                    title: 'Berhasil Dihapus',
                    text: response.data.data.messages,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  this.$refs.tableFe.refresh()
                })
                .catch(error => {
                  var message = "";
                  if(typeof error.response.data.message === 'object'){
                    message = error.response.data.message.errors[0].msg
                  } else {
                    message = error.response.data.message
                  }
                  this.$swal({
                    icon: 'Danger',
                    title: 'Terjadi kesalahan!',
                    text: message,
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                })
            }
          })
        },
        handleSearch() {
          this.$refs.tableFe.refresh()
        },
        getItems(ctx, callback) {
          const _ = this
    
          let endpoint = `${_.config.api}/detail-anggaran/${this.$route.params.type}/${this.$route.params.group_key}/?per_page=${_.perPage}&page=${_.currentPage}`
          if (_.search) {
            endpoint += `&key=${_.search}`
          }
          axios
            .get(endpoint)
            .then(resp => {
              const consume = resp.data.data
              _.perPage = consume.per_page
              _.currentPage = consume.current_page
              _.totalRows = consume.total
              callback(consume.data || [])
            })
            .catch(error => {
              console.error(error)

              var message = "";
              if(typeof error.response.data.message === 'object'){
                message = error.response.data.message.errors[0].msg
              } else {
                message = error.response.data.message
              }
              this.makeToast('danger', "Terjadi Kesalahan", message)
            })
          return null
        },
        handleSearch() {
          this.$refs.tableFe.refresh()
        },
        changePerPage() {
          const _ = this
          _.$refs.tableFe.refresh()
        },
        changeCurrentPage(currentPage) {
          let _ = this;
          _.currentPage = currentPage;
        },
        makeToast(variant = null, title = "Title here...", text = "Text here...") {
          this.$bvToast.toast(text, {
            title,
            variant,
            solid: true,
          });
        },
      },
    }
  
  </script>