<template>
  <b-row>
    <b-col v-if="can('read/bukti-bayar')" cols="12">
      <b-card title="Monitoring Pembayaran SPPD">
        <b-row class="pt-1 mb-2">
          <b-col md="9">
            <b-button-group
              class="d-none d-lg-inline-flex filter-status-container"
            >
              <b-button
                v-for="opt in filterOptions"
                :key="opt.text"
                :variant="filter === opt.value ? 'primary' : 'outline-primary'"
                @click="filter = opt.value"
              >
                {{ opt.text }}
              </b-button>
              <!-- <b-button
                :variant="filter === 0 ? 'primary' : 'outline-primary'"
                @click="filter = 0"
              >
                Approval PA
              </b-button>
              <b-button
                :variant="filter === 1 ? 'primary' : 'outline-primary'"
                @click="filter = 1"
              >
                Menunggu Dibayarkan
              </b-button>
              <b-button
                :variant="filter === 2 ? 'primary' : 'outline-primary'"
                @click="filter = 2"
              >
                Dibayarkan Lokas
              </b-button>
              <b-button
                :variant="filter === 3 ? 'primary' : 'outline-primary'"
                @click="filter = 3"
              >
                Dibayarkan Pospay
              </b-button> -->
            </b-button-group>
            <div class="d-block d-lg-none mb-1">
              <b-form-select
                v-model="filter"
                :options="filterOptions"
              ></b-form-select>
            </div>
          </b-col>

          <b-col md="3" xs="12">
            <b-input-group size="8">
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="'Cari disini...'"
              />
              <b-input-group-append>
                <b-button
                  class="btn-sm"
                  :disabled="!search"
                  @click="search = ''"
                >
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-table
          ref="tableFe"
          striped
          hover
          responsive
          show-empty
          class="position-relative"
          primary-key="email"
          :per-page="perPage"
          :current-page="currentPage"
          :items="getItems"
          :fields="fields"
          :busy="busy"
        >
          <template #table-busy>
            <div class="text-center my-3">
              <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
              <div>Loading data, please wait...</div>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(name)="data">
            {{ data.item.nippos }} / {{ data.item.name }}
          </template>

          <template #cell(total_days)="data">
            {{ data.item.total_days }} hari
          </template>

          <template #cell(destination_office)="data">
            {{ data.item.destination_office || '-' }}
          </template>

          <template #cell(paid_at)="data">
            {{ data.item.paid_at || '-' | moment('DD-MM-YYYY') }}
          </template>

          <template #cell(status)="data">
            {{ data.item.status || '-' }}
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div style="min-width: 100px">
              <b-button
                v-if="
                  (data.item.status === 'Dibayarkan Lokas' ||
                    data.item.status === 'Dibayarkan Pospay') &&
                  can('print/bukti-bayar')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Print'"
                variant="secondary"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="print(data.item.sppd_id, data.item.sppd_number)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>

              <b-button
                v-if="can('show/bukti-bayar') && can('show/bukti-bayar')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Detail'"
                variant="info"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="gotoDetailPage(data.item.sppd_id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_paid == 0 &&
                  data.item.status_admin == 0 &&
                  can('approval_admin/bukti-bayar')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Approve Admin'"
                variant="success"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="confirmApprove(data.item.sppd_id, 'admin')"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="
                  data.item.status_paid == 0 &&
                  data.item.status_admin == 1 &&
                  can('approval_pa/bukti-bayar')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Approve PA'"
                variant="success"
                size="sm"
                class="btn-icon float-sm-left mr-1 mb-1"
                @click="confirmApprove(data.item.sppd_id, 'pa')"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-if="
                  (data.item.status === 'Menunggu Approval Admin' ||
                    data.item.status === 'Menunggu Approval PA' ||
                    data.item.status === 'Menunggu Dibayarkan') &&
                  can('edit/bukti-bayar')
                "
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-popover.hover.bottom="'Ubah'"
                variant="warning"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoEditPage(data.item.sppd_id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </b-table>

        <span v-if="totalRows == 1" style="margin: 1rem"></span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="changePerPage()"
            />
          </b-form-group>

          <div>
            Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: 'red !important';
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BButton,
  BButtonGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCardBody,
  BFormGroup,
  BFormSelect,
  BPagination,
  VBPopover,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import debounce from 'debounce'

export default {
  name: 'SppdList',
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BButtonGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/payments',
        status: '/misc/status-paid',
      },
      filterOptions: [
        { value: null, text: 'Semua' },
        { value: '00', text: 'Menunggu Approval Admin' },
        { value: '01', text: 'Menunggu Approval PA' },
        { value: 1, text: 'Menunggu Dibayarkan' },
        // { value: 2, text: 'Dibayarkan Lokas' },
        { value: 3, text: 'Dibayarkan Pospay' },
      ],
      filter: null,
      search: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      from: 1,
      to: 10,
      sortBy: 'id',
      pageOptions: [1, 10, 25, 50, 100],
      fields: [
        'no',
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'name', label: 'NIPPOS / Nama', thStyle: 'min-width:200px' },
        { key: 'total_days', label: 'Lama Perjalanan' },
        { key: 'destination_office', label: 'Tujuan' },
        { key: 'paid_at', label: 'Tanggal Dibayarkan' },
        { key: 'status', label: 'Status' },
        { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1'},
      ],
      items: [],
      busy: false,
    }
  },
  watch: {
    filter: function () {
      this.$refs.tableFe.refresh()
    },
    search: debounce(function () {
      this.$refs.tableFe.refresh()
    }, 300),
  },
  mounted() {
    const _ = this
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text,
        )
      }
    }
    // this.getStatusList()
  },
  methods: {
    getStatusList() {
      axios
        .get(this.config.status)
        .then(resp => {
          if (resp.data.data?.length) {
            this.filterOptions = [
              ...this.filterOptions,
              ...resp.data.data.map(({ id, name }) => ({
                value: id,
                text: name,
              })),
            ]
          }
        })
        .catch(() => {
          // console.log(err)
        })
    },
    gotoDetailPage(id) {
      const _ = this
      _.$router.push(`/bukti-bayar/${id}/detail`)
    },
    gotoEditPage(id) {
      const _ = this
      _.$router.push(`/bukti-bayar/${id}/edit`)
    },
    getItems(ctx, callback) {
      const _ = this
      _.busy = true;

      let endpoint = `${_.config.api}?per_page=${_.perPage}&page=${_.currentPage}`
      if (_.filter !== null) {
        if (_.filter[0] == 0) {
          endpoint += `&status=0&status_admin=${_.filter[1]}`
        } else {
          endpoint += `&status=${_.filter}`
        }
      }
      if (_.search) {
        endpoint += `&key=${_.search}`
      }
      axios
        .get(endpoint)
        .then(resp => {
          const consume = resp.data.data
          _.perPage = consume.per_page
          _.currentPage = consume.current_page
          _.totalRows = consume.total
          _.from = consume.from || 0
          _.to = consume.to || 0
          callback(consume.data || [])
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Internal Server Error", message)
        })
        .finally(() => {
          _.busy = false;
        });
      return null
    },
    changePerPage() {
      const _ = this
      _.$refs.tableFe.refresh()
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    confirmApprove(id, type) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui Bukti Bayar SPPD tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        let url = ''
        let body = {}

        if (type === 'admin') {
          url = `${this.config.api}/${id}/approve-admin`
        }
        if (type === 'pa') {
          url = `${this.config.api}/${id}`
          body = { status: 1 }
        }

        if (result.value) {
          axios
            .put(url, body)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Berhasil Disetujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$refs.tableFe.refresh()
            })
            .catch(error => {
              var message = "";
              if(typeof error.response.data.message === 'object'){
                message = error.response.data.message.errors[0].msg
              } else {
                message = error.response.data.message
              }
              this.$swal({
                icon: 'Danger',
                title: 'Terjadi kesalahan!',
                text: message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    handleSearch() {
      this.$refs.tableFe.refresh()
    },
    print(id, sppdNumber) {
      axios
        .get(`${this.config.api}/${id}/print`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Bukti-Bayar-${sppdNumber}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    },
  },
}
</script>

<style>
.b-popover {
  font-size: 10px;
}

@media screen and (max-width: 1440px) {
  .filter-status-container button {
    font-size: 12px;
  }
}
</style>
