<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body">
              <span>{{ messages }}</span>
            </div>
          </b-alert>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                <b-col md="6">
                  <ValidationProvider
                    name="Role"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Role" label-for="mc-name">
                      <b-form-input
                        id="mc-name"
                        placeholder="Masukkan Role"
                        v-model="model.name"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                  <p class="fs-6 text-danger" v-if="errors.name != ''" style="margin-top: -15px;">
                    {{ errors.name[0] }}
                  </p>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Status"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group label="Status" label-for="mc-status">
                      <b-form-radio-group
                        v-model="model.status"
                        :options="statusList"
                        class="mt-1"
                        value-field="value"
                        text-field="label"
                      />
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider
                    name="Menu"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <p>List Menu</p>
                    <b-form-group label="">
                      <div v-for="item in menu_options" :key="item.id">
                        <b-form-checkbox
                        v-model="menus"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </b-form-checkbox>
                        
                        <div v-if="item.children && item.children.length" class="ml-3">
                          <b-form-checkbox
                            v-for="child in item.children"
                            :key="child.id"
                            v-model="menus"
                            :value="child.id"
                          >
                            {{ child.name }}
                          </b-form-checkbox>
                        </div>
                      </div>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                    
                    <!-- <b-form-group label="">
                      <b-form-checkbox
                        v-for="option in menu_options"
                        :key="option.id"
                        v-model="menus"
                        :value="option.id"
                        name="flavour-3a"
                      >
                        {{ option.name }}
                      </b-form-checkbox>

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group> -->
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                >
                  Simpan
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'manajemen-role' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BFormCheckboxGroup,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import MenuItem from "./MenuItem.vue";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormCheckboxGroup,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BOverlay,
    MenuItem,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {
        name: ''
      },
      showDismissibleAlert: false,
      config: {
        api: "/roles",
        menu: "/misc/menus",
        redirect: "manajemen-role",
      },
      menus: [],
      model: {
        name: '',
        status: '',
        menus: []
      },
      menu_options: [],
      statusList: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Tidak Aktif",
          value: 0,
        },
      ],
      messages: "",
      isLoading: false,
    };
  },
  mounted() {
    let _ = this;
    _.isLoading = true;
    if (this.$route.meta.action != "store") {
      _.getData();
    }
    _.getMenu();
  },
  methods: {
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
    updateParent(item){
      console.log(item);
      
    },
    buildTree(data) {
      const tree = [];
      const map = {};

      // Inisialisasi map berdasarkan id
      data.forEach(item => (map[item.id] = { ...item, children: [] }));

      // Bangun hierarki
      data.forEach(item => {
        if (item.parent === 0) {
          tree.push(map[item.id]);
        } else if (map[item.parent]) {
          map[item.parent].children.push(map[item.id]);
        }
      });

      return tree;
    },
    getMenu() {
      let _ = this;
      axios.get(_.config.menu).then((response) => {
        let _ = this;
        // _.menu_options = this.buildTree(response.data.data);
        _.menu_options = response.data.data; 
        _.isLoading = false;
      })
      .catch(error => {
        _.isLoading = false;
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id + "/menus")
          .then((response) => {
            let _ = this;
            let permissioning = [];
            _.model = response.data.data;
            for (const i in _.model.menus) {
              this.menus.push(_.model.menus[i].id);
            }
          })
          .catch(error => {
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      }
    },
    save() {
      let _ = this;
      _.isLoading = true;
     _.model.menus = _.menus;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.isLoading = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Tambah Data Berhasil",
                text: "Data baru berhasil ditambahkan",
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.isLoading = false;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        // let data = {
        //   name: _.model.name,
        //   status: _.model.status,
        //   menu_ids: _.model.menu_ids,
        // };
        axios
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Ubah Data Berhasil",
                text: "Data berhasil diubah",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      }
    },
  },
};
</script>
