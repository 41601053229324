<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body">
              <span>{{ messages }}</span>
              <span v-show="scrollToTop()"></span>
            </div>
          </b-alert>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                <b-col md="6">
                  <ValidationProvider
                    name="Nama agen"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="agen_name"
                        >Nama Agen <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-input
                        id="mc-name"
                        placeholder="Masukkan Nama Agen"
                        v-model="model.agen_name"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Nama petugas (PIC)"
                    :rules="'required'"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="pic_name"
                        >Nama Petugas (PIC)
                        <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-input
                        id="mc-name"
                        placeholder="Masukkan Nama Petugas (PIC)"
                        v-model="model.pic_name"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Nomor ponsel (PIC)"
                    :rules="'required|numeric|min:8|max:14'"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="phone_number"
                        >Nomor Ponsel (PIC)
                        <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-input
                        id="mc-name"
                        type="number"
                        placeholder="Masukkan Nomor Ponsel (PIC)"
                        v-model="model.phone_number"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Email kantor"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="email"
                        >Email Kantor
                        <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-input
                        id="mc-email"
                        type="email"
                        placeholder="Masukkan Email Kantor"
                        v-model="model.email"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="No rekening POSPAY"
                    :rules="'required'"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="pgm_number"
                        >No Rekening POSPAY
                        <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-input
                        id="mc-name"
                        type="number"
                        placeholder="Masukkan No Rekening POSPAY"
                        v-model="model.pgm_number"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Alamat kantor"
                    :rules="'required'"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="address"
                        >Alamat Kantor
                        <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-textarea
                        id="mc-name"
                        rows="3"
                        placeholder="Masukkan Alamat Kantor"
                        v-model="model.address"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="No PKS"
                    :rules="'required'"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="pks_number">
                        No PKS <span class="fs-6 text-danger">*</span>
                      </label>
                      <b-form-input
                        id="mc-name"
                        type="text"
                        placeholder="Masukkan No PKS"
                        v-model="model.pks_number"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <ValidationProvider
                    name="Status"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="status"
                        >Status <span class="fs-6 text-danger">*</span></label
                      >
                      <b-form-radio-group
                        v-model="model.status"
                        :options="statusList"
                        class="mt-1"
                        value-field="value"
                        text-field="label"
                      />
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="8">
                  <label
                    >Masa Berlaku PKS
                    <span class="fs-6 text-danger">*</span></label
                  >
                </b-col>
                <b-col md="4">
                  <label>NPWP <span class="fs-6 text-danger">*</span></label>
                </b-col>
                <b-col md="4">
                  <ValidationProvider
                    name="Tanggal mulai"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <date-picker
                        :lang="lang"
                        :format="formats"
                        :firstDayOfWeek="1"
                        v-model="model.start_date"
                        placeholder="Tanggal mulai"
                        name="start_date"
                        type="date"
                        value-type="format"
                        :default-value="new Date()"
                      ></date-picker>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="4">
                  <ValidationProvider
                    name="Tanggal akhir"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <date-picker
                        :lang="lang"
                        :format="formats"
                        v-model="model.end_date"
                        name="end_date"
                        placeholder="Tanggal akhir"
                        type="date"
                        value-type="format"
                        :disabled-date="disabledBeforeStartDate"
                      ></date-picker>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="4">
                  <ValidationProvider
                    name="npwp"
                    rules="required|min:20|max:20"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <b-form-input
                        id="npwp"
                        v-model="model.npwp"
                        placeholder="Masukan NPWP"
                        v-mask="'##.###.###.#-###.###'"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- <b-col md="8">
                    <p class="fs-6 text-danger" style="margin: -15px 0 20px 0;">Tanggal mulai harus lebih kecil daripada tanggal akhir</p>
                  </b-col> -->
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                  :disabled="isLoading"
                >
                  <div v-if="isLoading">
                    Simpan <feather-icon icon="LoaderIcon" />
                  </div>
                  <div v-else>Simpan</div>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'mitra' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import { mask } from 'vue-the-mask'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BImg,
    BOverlay,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
    mask,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {
        agen_name: '',
        pic_name: '',
        email: '',
        phone_number: '',
        address: '',
        pgm_number: '',
        pks_number: '',
        start_date: '',
        end_date: '',
        status: '',
        npwp: '',
      },
      showDismissibleAlert: false,
      config: {
        api: '/mitras',
        redirect: 'mitra',
      },
      model: {
        agen_name: '',
        pic_name: '',
        email: '',
        phone_number: '',
        address: '',
        pgm_number: '',
        pks_number: '',
        start_date: '',
        end_date: '',
        status: 1,
        npwp: '',
      },
      logos: null,
      logo_prev: null,
      confirmPass: null,
      error_link: true,
      statusList: [
        {
          label: 'Aktif',
          value: '1',
        },
        {
          label: 'Tidak Aktif',
          value: '0',
        },
      ],
      messages: '',
      error_date: '',
      isLoading: false,
      formats: 'YYYY-MM-DD',
      lang: {
        days: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days',
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range',
        },
      },
    }
  },
  mounted() {
    let _ = this
    if (this.$route.meta.action != 'store') {
      _.isLoading = true;
      _.getData()
    }
  },
  methods: {
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    disabledBeforeStartDate(date) {
      return date < new Date(this.model.start_date)
    },
    getData() {
      let _ = this
      if (this.$route.meta.action == 'edit') {
        axios.get(_.config.api + '/' + this.$route.params.id).then(response => {
          let _ = this
          _.model = response.data.data
          _.isLoading = false;
        })
        .catch(error => {
          _.isLoading = false;
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
      }
    },
    save() {
      let _ = this
      if (_.model.start_date > _.model.end_date) {
        _.model.start_date = ''
        _.model.end_date = ''
        _.error_date = 'Tanggal mulai harus lebih kecil daripada tanggal akhir'
      } else {
        /* eslint-disable-next-line */
        if (_.$route.meta.action == 'store') {
          _.isLoading = true
          axios
            .post(_.config.api, _.model)
            .then(res => {
              _.$router.push({
                name: _.config.redirect,
                params: {
                  event: 'success',
                  title: 'Tambah Data Berhasil',
                  text: 'Data baru berhasil ditambahkan',
                },
              })
              _.isLoading = false
            })
            .catch(e => {
              let vm = this
              vm.showDismissibleAlert = true
              if (e.response?.data?.data?.pgm_number.length) {
                vm.messages = e.response.data.data.pgm_number[0]
                vm.isLoading = false
              } else if (typeof e.response.data.message === 'object') {
                vm.errors = e.response.data.data
                vm.isLoading = false
              } else {
                vm.messages = e.response.data.message
                vm.errors = e.response.data.data
                vm.isLoading = false
              }
            })
        } else {
          _.model.id = _.$route.params.id
          _.isLoading = true
          axios
            .put(_.config.api + '/' + _.$route.params.id, _.model)
            .then(res => {
              _.$router.push({
                name: _.config.redirect,
                params: {
                  event: 'success',
                  title: 'Ubah Data Berhasil',
                  text: 'Data berhasil diubah',
                },
              })
              _.isLoading = false
            })
            .catch(e => {
              let vm = this
              vm.showDismissibleAlert = true
              if (e.response?.data?.data?.pgm_number.length) {
                vm.messages = e.response.data.data.pgm_number[0]
                vm.isLoading = false
              } else if (typeof e.response.data.message === 'object') {
                vm.errors = e.response.data.data
                vm.isLoading = false
              } else {
                vm.messages = e.response.data.message
                vm.errors = e.response.data.data
                vm.isLoading = false
              }
            })
        }
      }
    },
  },
}
</script>
