<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body">
              <span>{{ messages }}</span>
            </div>
          </b-alert>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row class="ml-1">
                  <b-col md="6">
                    <p style="margin: 0 0 0 10px;">Menu</p>
                  </b-col>
                  <b-col md="6">
                    <p>Permission</p>
                  </b-col>
                  <b-col md="12">
                  <span v-for="option in menu_options" :key="option.id" class="row-line">
                      <b-form-group label="" class="col-md-6">
                          {{ option.name }}
                      </b-form-group>
                    <ValidationProvider class="col-md-6"
                    name="Permission"
                    rules="required"
                    v-slot="{ errors }"
                  >
                      <b-form-group label="">
                        <b-form-checkbox
                          v-for="row in option.permission"
                          :key="row.id"
                          v-model="permissions"
                          :value="row.id"
                          name="flavour-3a"
                        >
                          {{ row.name }}
                        </b-form-checkbox>

                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                  </ValidationProvider>
                    <p class="fs-6 text-danger" v-if="errors.menus != ''" style="margin-top: -27px;">
                      {{ errors.menus[0] }}
                    </p>
                  </span>
                  </b-col>
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                >
                  Simpan
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'manajemen-role' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BOverlay,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {
        menus: ''
      },
      showDismissibleAlert: false,
      config: {
        api: "/roles/assign-permissions",
        menu: "/roles",
        redirect: "manajemen-role",
      },
      menus: [],
      permissions: [],
      model: {
        role_id: '',
        permissions: []
      },
      menu_options: [],
      statusList: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Tidak Aktif",
          value: 0,
        },
      ],
      messages: "",
      isLoading: false,
    };
  },
  mounted() {
    let _ = this;
    _.isLoading = true;
    _.getMenu();
    _.getData();
  },
  methods: {
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
    getMenu() {
      let _ = this;
      axios.get(_.config.menu + '/' + _.$route.params.id + '/menus').then((response) => {
        let _ = this;
        _.menu_options = response.data.data.menus;

      })
      .catch(error => {
        _.isLoading = false;

        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    getData() {
      let _ = this;
      axios
        .get(_.config.menu + '/' + this.$route.params.id + '/permissions')
        .then((response) => {
          let _ = this;
          let permissioning = [];
          _.model = response.data.data;
          for (const i in _.model.permissions) {
            this.permissions.push(_.model.permissions[i].id);
          }
          _.isLoading = false;

        })
        .catch(error => {
          _.isLoading = false;
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    save() {
      let _ = this;
      _.isLoading = true;

      _.model.role_id = _.$route.params.id;
      _.model.permissions = _.permissions;
      axios
        .post(_.config.api, _.model)
        .then((res) => {
          _.isLoading = false;

          _.$router.push({
            name: _.config.redirect,
            params: {
              event: "success",
              title: "Assign Permission Berhasil",
              text: "Permission berhasil ditambahkan",
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.isLoading = false;

          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
            vm.errors = e.response.data.data;
          } else {
            vm.messages = e.response.data.message;
            vm.errors = e.response.data.data;
          }
        });
    }
  },
};
</script>
