<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row>
      <b-col cols="12">
        <b-card title="Detail NPP">
          <b-row>
            <b-col cols="6" md="4" class="mb-2">
              <h6>No. Proposal</h6>
              <p class="card-text mb-25">
                {{ npp.proposal_number || "-" }}
              </p>
            </b-col>
            <b-col cols="6" md="4" class="mb-2">
              <h6>Kantor</h6>
              <p class="card-text mb-25" >
                {{ npp.regional_name.toUpperCase() || "-" }}
              </p>
            </b-col>
            <b-col cols="6" md="4" class="mb-2">
              <h6>No. Proposal Regional</h6>
              <p class="card-text mb-25">
                {{ npp.code_regional.toUpperCase() || '-' }}
              </p>
            </b-col>
            <b-col cols="6" md="4" class="mb-2">
              <h6>Jumlah Pegawai</h6>
              <p class="card-text mb-25">
                {{ npp.total_employees || '-' }}
              </p>
            </b-col>
            <b-col cols="6" md="4" class="mb-2">
              <h6>Tanggal Generate</h6>
              <p class="card-text mb-25">
                {{ npp.date | moment('DD/MM/YYYY') }}
              </p>
            </b-col>
          </b-row>
          <b-table
            :items="npp_details"
            :fields="fields"
            responsive
            striped
            show-empty
          >
            <template #empty>
              <div class="text-center my-2">Data tidak tersedia</div>
            </template>
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(name)="data">
              {{ data.item.employee }} / {{ data.item.name }}
            </template>
            <template #cell(daily_money)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.daily_money | currency }}
              </span>
            </template>
            <template #cell(total_pengeluaran_rill)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_pengeluaran_rill | currency }}
              </span>
            </template>
            <template #cell(total_accomodation)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_accomodation | currency }}
              </span>
            </template>
            <template #cell(total_transportation)="data">
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_transportation | currency }}
              </span>
            </template>
            <template #cell(total_cost)="data" >
              <span class="text-capitalize d-block" style="text-align: end;">
                {{ data.item.total_cost | currency }}
                <!-- {{
                  (data.item.daily_money +
                    data.item.total_pengeluaran_rill +
                    data.item.total_accomodation +
                    data.item.total_transportation)
                    | currency
                }} -->
              </span>
            </template>
            <template #cell(kwitansi)="data">
              <b-button
                :href="data.item.folder_url"
                variant="info"
                target="_blank"
                size="sm"
              >
                Cek Kwitansi
              </b-button>
            </template>
          </b-table>
          <b-card-footer align="right">
            <b-button variant="secondary" @click="$router.back()">
              Kembali
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BTable, BButton, BOverlay } from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: { BRow, BCol, BCard, BCardFooter, BTable, BButton, BOverlay },
  data() {
    return {
      isLoading: false,
      npp: {},
      npp_details: [],
      fields: [
        'no',
        // { key: 'nomor_dokumen', label: 'Nomor Dokumen' },
        // { key: 'kdvendor', label: 'Kode Vendor' },
        { key: 'name', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_pengeluaran_rill', label: 'Biaya Pengeluaran Rill' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_cost', label: 'Total Biaya' },
        // { key: 'target_condition', label: 'Target Condition' },
        // { key: 'jenisrelasi', label: 'Jenis Relasi' },
        // { key: 'create_nopen', label: 'Create Nopen' },
        // { key: 'kodecgs', label: 'Kode CGS' },
        // { key: 'ktr', label: 'Kantor' },
        // { key: 'bsu_korek', label: 'BSU Korek' },
        // { key: 'bsu_pajak', label: 'BSU Pajak' },
        { key: 'kwitansi', label: 'Kwitansi' },
      ],
    }
  },
  mounted() {
    this.getNpp()
  },
  methods: {
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    getNpp() {
      this.isLoading = true
      axios.get(`/npps/detail/${this.$route.params.id}`).then(res => {
        this.npp = res.data.data[0];
        this.npp_details = res.data.data['npp_details'];

        console.log(res.data.data['npp_details']);
        
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
  },
}
</script>

<style></style>
