<template>
  <b-card>
    <b-card-body class="ml-5">
      <b-overlay
        variant="white"
        :show="isLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <b-row>
          <b-col cols="12" xl="5" class="mx-auto mb-2">
            <b-avatar size="60" :src="userData.avatar" class="badge-minimal">
              <feather-icon v-if="!userData.user" icon="UserIcon" size="22" />
            </b-avatar>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">NIPPOS</h6>
            <p class="card-text mb-25">
              {{ model.nippos }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Nama</h6>
            <p class="card-text mb-25">
              {{ model.nama }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Alamat Email</h6>
            <p class="card-text mb-25">
              {{ model.email != '' ? model.email : '-' }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">NPWP</h6>
            <p class="card-text mb-25">{{ model.npwp }}</p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jabatan</h6>
            <p class="card-text mb-25">
              {{ model.descjabatan }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Div/Reg/UPT</h6>
            <p class="card-text mb-25">
              {{ model.namaktr }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Bagian Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ model.namabagian }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Kantor Asal</h6>
            <p class="card-text mb-25">
              {{ model.namaktr }} - {{ model.nopend }}
            </p>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card-body>
    <b-card-footer>
      <!-- back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-secondary"
        @click="$router.go(-1)"
      >
        Kembali
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BAvatar,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BAvatar,
    BOverlay,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      isLoading: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      config: {
        api: '/me',
      },
      model: {
        position: [],
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true
      axios.get(this.config.api).then(response => {
        this.model = response.data.data
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
  },
}
</script>
