var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"variant":"white","show":_vm.isLoading,"spinner-variant":"primary","blur":"0","opacity":".5","rounded":"sm"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade"},{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body mb-2"},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-card-body',{staticClass:"p-0"},[_c('b-form-group',[_c('label',{attrs:{"label-for":"cost-component"}},[_vm._v("Komponen Biaya "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Komponen Biaya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"cost-component","options":_vm.costComponentOptions},model:{value:(_vm.model.costComponent),callback:function ($$v) {_vm.$set(_vm.model, "costComponent", $$v)},expression:"model.costComponent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"label-for":"note"}},[_vm._v("Keterangan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"note","placeholder":"Masukkan keterangan..."},model:{value:(_vm.model.note),callback:function ($$v) {_vm.$set(_vm.model, "note", $$v)},expression:"model.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('label',{attrs:{"label-for":"cost"}},[_vm._v("Biaya "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-numeric',{staticClass:"form-control",attrs:{"id":"cost","placeholder":"Masukkan Biaya","currency":"Rp","separator":"."},nativeOn:{"keypress":function($event){return _vm.numbersOnly.apply(null, arguments)}},model:{value:(_vm.model.cost),callback:function ($$v) {_vm.$set(_vm.model, "cost", $$v)},expression:"model.cost"}})],1),_c('b-form-group',[_c('label',{attrs:{"label-for":"inputKwitansi"}},[_vm._v("Upload Kwitansi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Kwitansi","rules":_vm.metaAction === 'edit' ? '' : "required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"inputKwitansi","placeholder":_vm.placeholderFile,"drop-placeholder":"Taruh file disini...","accept":".jpg, .png, .gif, .doc, .docx, .pdf"},on:{"change":_vm.handleUploadKwitansi},model:{value:(_vm.kwitansi),callback:function ($$v) {_vm.kwitansi=$$v},expression:"kwitansi"}}),(_vm.currentReceiptUrl)?_c('b-link',{staticClass:"d-block text-info mt-1",attrs:{"href":_vm.currentReceiptUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.currentReceiptUrl.split('/').at(-1))+" ")]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-card-footer',{staticClass:"px-0",attrs:{"align":"right"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.goBack}},[_vm._v(" Kembali ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"disabled":_vm.loadingUpload,"variant":"primary","type":"submit"}},[_vm._v(" Simpan ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }