<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body">
              <span>{{ messages }}</span>
            </div>
          </b-alert>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                <b-col md="12">
                  <ValidationProvider
                    name="Nama komponen biaya"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group>
                      <label label-for="name">Nama Komponen Biaya <span class="fs-6 text-danger">*</span></label>
                      <b-form-input
                        id="mc-name"
                        placeholder="Masukkan Nama Komponen Biaya"
                        v-model="model.name"
                      />

                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                  <p class="fs-6 text-danger" v-if="errors.name != ''" style="margin-top: -27px;">
                    {{ errors.name[0] }}
                  </p>
                </b-col>
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                >
                  Simpan
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'komponen-biaya' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BImg,
  BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BImg,
    BOverlay,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {
        name: ''
      },
      showDismissibleAlert: false,
      config: {
        api: "/cost-components",
        redirect: "komponen-biaya",
      },
      model: {
        name: ''
      },
      logos:null,
      logo_prev:null,
      confirmPass: null,
      error_link:true,
      messages: "",
      isLoading: false,
    };
  },
  mounted() {
    let _ = this;
    if (this.$route.meta.action != "store") {
      _.getData();
    }
  },
  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
          })
          .catch(error => {
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      }
    },
    save() {
      let _ = this;
      _.isLoading = true;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.isLoading = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Tambah Data Berhasil",
                text: "Data baru berhasil ditambahkan",
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.isLoading = false;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Ubah Data Berhasil",
                text: "Data berhasil diubah",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
              vm.errors = e.response.data.data;
            } else {
              vm.messages = e.response.data.message;
              vm.errors = e.response.data.data;
            }
          });
      }
    },
  },
};
</script>
