<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body">
              <span>{{ messages }}</span>
            </div>
          </b-alert>
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-row>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="No NIK KTP" label-for="mc-name">
                              <b-form-input
                              id="mc-name"
                              placeholder="Masukkan No NIK KTP"
                              v-model="model.name"
                              />

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="Nama" label-for="mc-name">
                              <b-form-input
                              id="mc-name"
                              placeholder="Masukkan Nama"
                              v-model="model.name"
                              />

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="Tempat Lahir" label-for="mc-name">
                              <b-form-input
                              id="mc-name"
                              placeholder="Masukkan Tempat Lahir"
                              v-model="model.name"
                              />

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Last Update"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="Tanggal Lahir" label-for="mc-date">
                              <date-picker
                              :firstDayOfWeek="1"
                              placeholder="Masukkan Tanggal Lahir"
                              v-model="model.name"
                              name="date"
                              :lang="lang"
                              :format="formats"
                              value-type="YYYY-MM-DD"
                              ></date-picker>
                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="NPWP" label-for="mc-name">
                              <b-form-input
                              id="mc-name"
                              placeholder="Masukkan NPWP"
                              v-model="model.name"
                              />

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="Kantor Asal" label-for="mc-name">
                              <b-form-input
                              id="mc-name"
                              placeholder="Masukkan Kantor Asal"
                              v-model="model.name"
                              />

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
                  <b-col md="6">
                      <ValidationProvider
                      name="Nama"
                      rules="required"
                      v-slot="{ errors }"
                      >
                          <b-form-group label="Jenis Non Pegawai" label-for="mc-data-period">
                              <select class="form-control" v-model="model.data_periode">
                              <option value="">Pilih Jenis Non Pegawai</option>
                              <option
                                  v-for="(i, index) in jenis_nonpegawai"
                                  :key="index"
                                  :value="i.value"
                              >
                                  {{ i.label }}
                              </option>
                              </select>

                              <p class="fs-6 text-danger">
                              {{ errors[0] }}
                              </p>
                          </b-form-group>
                      </ValidationProvider>
                  </b-col>
              </b-row>
              <b-card-footer>
                <!-- submit and reset -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1 float-right"
                >
                  Simpan
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  class="mr-1 float-right"
                  variant="outline-secondary"
                  :to="{ name: 'manajemen-nonpegawai' }"
                >
                  Batal
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BOverlay,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      isLoading: false,
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "/category_indicator",
        redirect: "category_indicators",
      },
      model: {},
      confirmPass:null,
      jenis_nonpegawai: [
        {
          label: "Direksi",
          value: "Direksi",
        },
        {
          label: "Tenaga Kontrak",
          value: "Tenaga Kontrak",
        },
        {
          label: "Mitra Kerja",
          value: "Mitra Kerja",
        },
        {
          label: "Tamu Perusahaan",
          value: "Tamu Perusahaan",
        },
        {
          label: "Pengurus Pensiunan POS",
          value: "Pengurus Pensiunan POS",
        },
        {
          label: "Pendamping Komisaris / Direksi",
          value: "Pendamping Komisaris / Direksi",
        },
      ],
      messages: "",
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        _.isLoading = true;
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
            _.isLoading = false;
          })
          .catch(error => {
            this.isLoading = false 
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      }
    },
    save() {
      let _ = this;
      _.isLoading = true;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.isLoading = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Tambah Data Berhasil",
                text: "Data baru berhasil ditambahkan",
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.isLoading = false;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        let data = {
          name: _.model.name,
          status: _.model.status,
        };
        axios
          .put(_.config.api + "/" + _.$route.params.id, data)
          .then((res) => {
            _.isLoading = false;
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Ubah Data Berhasil",
                text: "Data berhasil diubah",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.isLoading = false;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
};
</script>
