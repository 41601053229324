<template>
  <b-row>
    <b-col cols="12" v-if="can('read/manajemen-pegawai')">
      <b-card title="Manajemen Pegawai">
        <b-row class="pb-2">
          <b-col> </b-col>
          <b-col md="3" xs="6" class="mb-1">
            <b-input-group size="8">
              <v-select
                id="status"
                v-model="selectedStatus"
                label="text"
                placeholder="Cari Status"
                :options="selectedStatusOptions"
                class="form-select"
                style="width: 100%;"
              />
              <!-- <multiselect
                v-model="selectedStatus"
                :options="selectedStatusOptions"
                label="text"
                track-by="value"
                class="w-100"
              ></multiselect> -->
              <!-- {{ selectedStatus }} -->
            </b-input-group>
          </b-col>
          <b-col md="4" xs="6">
            <b-input-group size="8">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Cari disini..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
          ref="tableFe"
          striped
          hover
          responsive
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :items="filteredItems"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :busy="busy"
          @filtered="onFiltered"
        >

          <template #table-busy>
            <div class="text-center my-3">
              <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
              <div>Loading data, please wait...</div>
            </div>
          </template>

          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #emptyfiltered>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>

          <template #cell(no)="data">
            {{ (currentPage - 1) * perPage + (data.index + 1) }}
          </template>

          <template #cell(nopend)="data">
            {{ data.item.namaktr }} - {{ data.item.nopend }}
          </template>

          <template #cell(statuskerja)="data">
            <span v-if="data.item.statuskerja == 1"> Aktif </span>
            <span v-else-if="data.item.statuskerja == 2"> CBS Akhir </span>
            <span v-else-if="data.item.statuskerja == 10">
              Proses Sertijab
            </span>
            <span v-else-if="data.item.statuskerja == 'Z'">
              Tidak Menjalani MPP
            </span>
            <span v-else>
              {{ data.item.statuskerja }}
            </span>
          </template>

          <!-- A virtual column -->
          <template #cell(Aksi)="data">
            <div>
              <b-button
                v-if="can('show/manajemen-pegawai')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-b-popover.hover.bottom="'Detail'"
                size="sm"
                class="btn-icon float-sm-left mr-1"
                @click="gotoDetailPage(data.item.nippos)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <span v-if="totalRows == 1" style="margin: 1rem">{{ totalRows }}</span>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <div class="d-flex align-items-center">
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
                @change="changePerPage()"
              />
            </b-form-group>
            <!-- Display total data -->
            <small class="text-normal">Total data: {{ totalRows }}</small>
          </div>

          <!-- <div>
            Menampilkan 1 hingga 10 dari 57 entri
          </div> -->
          
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            />
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
// import Multiselect from 'vue-multiselect'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
    // Multiselect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-popover': VBPopover,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      config: {
        api: '/employees',
        api_employee: '/misc/employees/v3',
      },
      perPage: 10,
      pageOptions: [1, 10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'nama',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        'no',
        { key: 'nama', label: 'Nama', thStyle: 'min-width:200px' },
        { key: 'nippos', label: 'NIPPOS' },
        { key: 'namabagian', label: 'Bagian', thStyle: 'min-width:250px' },
        { key: 'descjabatan', label: 'Jabatan', thStyle: 'min-width:160px' },
        { key: 'nopend', label: 'Kantor', thStyle: 'min-width:260px' },
        { key: 'statuskerja', label: 'Status', thStyle: 'min-width:150px' },
        { key: 'Aksi', label: 'Aksi', stickyColumn: true, class: 'sticky-column' },
      ],
      items: [],
      busy: false,
      selectedStatus: null,
      selectedStatusOptions: [
        { text: 'Aktif', value: '1'},
        { text: 'CBS Akhir', value: '2'},
        { text: 'Proses Sertijab', value: '10' },
        { text: 'Tidak Menjalani MPP', value: 'Z' }
      ],
    }
  },
  computed: {
    sortOptions() {  
      console.log("test 8");
    
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filteredItems() {
      // console.log(this.selectedStatus);
      // if(this.selectedStatus != null) {
      //   console.log(this.selectedStatus.value);
      // }
      
      // Jika tidak ada status yang dipilih, tampilkan semua item
      if (!this.selectedStatus) {
        return this.items;
      }

      // Filter berdasarkan status
      return this.items.filter((item) => item.statuskerja === this.selectedStatus.value);
    },
  },
  mounted() {
    console.log("test 7");

    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
    changePerPage() {
      console.log("test 1");

      const _ = this
      _.$refs.tableFe.refresh()
    },
    onFiltered(filteredItems) {
      console.log("test 2");
      
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    gotoDetailPage(id) {
      console.log("test 3");

      let _ = this
      _.$router.push('/manajemen-pegawai/' + id + '/detail')
    },
    get() {
      console.log("test 4");

      let _ = this
      let uri = _.config.api
      axios
        .get(uri + '?company=' + _.userData.nopend)
        .then(resp => {
          _.items = resp.data.data
          _.totalRows = _.items.length
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
    },
    getEmployee() {
      console.log("test 5");

      let _ = this
      _.busy = true;
      let uri = _.config.api_employee
      axios
        .get(uri + '?region=' + _.userData.regional)
        .then(resp => {
          _.items = resp.data.data
          _.totalRows = _.items.length
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
        .finally(() => {
          _.busy = false;
        });
    },
  },
  created() {
    console.log("test 6");

    let _ = this
    if (_.userData.role_name == 'Admin Nasional') {
      _.getEmployee()
    } else {
      _.get()
    }
  },
}
</script>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
