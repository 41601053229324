<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="newMenu"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import axios from "@axios";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  created() {
    let _ = this
    _.getMenu();
    _.getPermission();
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      config:{
        menu: "/me/menu",
        roles : "/roles/"
      },
      newMenu: [],
      newPermission: []
      // newMenu:[
      //   {
      //     title: "Dashboard",
      //     route: "home",
      //     icon: "HomeIcon",
      //   },
      //   {
      //     title: "Manajemen Pegawai",
      //     route: "manajemen-pegawai",
      //     icon: "UsersIcon",
      //   },
      //   {
      //     title: "Manajemen Non Pegawai",
      //     route: "manajemen-nonpegawai",
      //     icon: "UsersIcon",
      //   },
      //   {
      //     title: "Manajemen Role",
      //     route: "manajemen-role",
      //     icon: "UserIcon",
      //   },
      //   {
      //     title: "Master Data",
      //     route: "",
      //     icon: "DatabaseIcon",
      //     children: [
      //       {
      //         title: "Komponen Biaya",
      //         route: "komponen-biaya",
      //       },
      //       {
      //         title: "Alat Transportasi",
      //         route: "alat-transportasi",
      //       },
      //       {
      //         title: "Mitra",
      //         route: "mitra",
      //       },
      //       {
      //         title: "Uang Harian",
      //         route: "uang-harian",
      //       },
      //       {
      //         title: "PPH21",
      //         route: "pph21",
      //       },
      //     ]
      //   },
      //   {
      //     title: "Manajemen SPPD",
      //     route: "manajemen-sppd",
      //     icon: "FileIcon",
      //   },
      //   {
      //     title: "Pengeluaran Rill",
      //     route: "pengeluaran-rill",
      //     icon: "FileTextIcon",
      //   },
      //   {
      //     title: "Bukti Bayar",
      //     route: "bukti-bayar",
      //     icon: "FileTextIcon",
      //   },
      //   {
      //     title: "Laporan",
      //     route: "laporan",
      //     icon: "CopyIcon",
      //   },
      // ]
    }
  },
  methods: {
    realese()
    {
      const mapp = this.newMenu.map((item) => {
        const container = {};
        container.id = item.id;
        container.title = item.name;
        container.route = item.slug;
        container.icon = item.icon;
        container.children = this.checking(item.submenu);
        return container;
      });
      this.newMenu = mapp;
    },
    checking(data) {
      if (data.length == 0) {
        return null;
      } else {
          const mapp = data.map((item) => {
            const container = {};
            container.title = item.name;
            container.route = item.slug;
            return container;
          });
          return mapp;
      }
    },
    getMenu()
    {
      let _ = this
      axios
        .get(_.config.menu)
        .then((resp) => {
          const consume = resp.data.data;
          this.newMenu = consume
          this.realese();
          localStorage.setItem('all_menu', JSON.stringify(consume))
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermission()
    {
      let _ = this
      axios
        .get(_.config.roles + _.userData.role_id + "/permissions")
        .then((resp) => {
          const consume = resp.data.data.permissions;
          this.newPermission = consume
          localStorage.setItem('all_permission', JSON.stringify(consume))
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
