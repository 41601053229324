<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row class="match-height">
      <b-col cols="12">
        <b-card :title="this.$route.meta.title">
          <b-alert
            v-height-fade
            dismissible
            v-model="showDismissibleAlert"
            fade
            class="mb-2"
            variant="danger"
            v-id="showDismissibleAlert == true"
          >
            <div class="alert-body mb-2">
              <span class="text-danger">{{ messages }}</span>
            </div>
          </b-alert>

          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(save)">
              <b-card-body class="p-0">
                <!-- Biaya -->
                <!-- <b-form-group label="Biaya" label-for="cost"> -->
                <b-form-group>
                  <label label-for="cost">Biaya <span class="text-danger">*</span></label>
                  <vue-numeric
                    id="cost"
                    v-model="model.cost"
                    @keypress.native="numbersOnly"
                    class="form-control"
                    placeholder="Masukkan Biaya"
                    currency="Rp"
                    separator="."
                  ></vue-numeric>
                </b-form-group>

                <!-- Uraian -->
                <!-- <b-form-group label="Uraian" label-for="note"> -->
                <b-form-group>
                  <label label-for="note">Uraian <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Uraian"
                    rules="required"
                  >
                    <b-form-input
                      id="note"
                      v-model="model.note"
                      placeholder="Masukkan uraian..."
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <!-- Upload Kwitansi -->
                <!-- <b-form-group label="Upload Kwitansi" label-for="inputKwitansi"> -->
                <b-form-group>
                  <label label-for="inputKwitansi">Upload Kwitansi <span class="text-danger">*</span></label>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Kwitansi"
                    :rules="metaAction === 'edit' ? '' : `required`"
                  >
                    <b-form-file
                      id="inputKwitansi"
                      v-model="kwitansi"
                      :placeholder="placeholderFile"
                      drop-placeholder="Taruh file disini..."
                      accept=".jpg, .png, .gif, .doc, .docx, .pdf"
                      @change="handleUploadKwitansi"
                    ></b-form-file>
                    <b-link
                      v-if="currentReceiptUrl"
                      :href="currentReceiptUrl"
                      target="_blank"
                      class="d-block text-info mt-1"
                    >
                      {{ currentReceiptUrl.split('/').at(-1) }}
                    </b-link>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-card-body>
              <b-card-footer align="right" class="px-0">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  @click="goBack"
                >
                  Kembali
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  :disabled="loadingUpload"
                  class="ml-1"
                  variant="primary"
                  type="submit"
                >
                  Simpan
                </b-button>
              </b-card-footer>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  name: 'SppdAccommodationForm',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BLink,
    BOverlay,
  },
  directives: { Ripple },
  data() {
    const { sppdId, sppdAccommodationId, actionTab, beforeLink } = this.$route.params
    const { action: metaAction } = this.$route.meta

    return {
      sppdId,
      sppdAccommodationId,
      actionTab,
      beforeLink,
      metaAction,
      config: {
        api: '/sppd/accomondation',
        redirect: `/biaya-akomodasi/${sppdId}`,
        redirectDetail: `/manajemen-sppd/${sppdId}/detail`,
      },
      model: {
        cost: 0,
        note: '',
        document: '',
      },
      currentReceiptUrl: null,
      kwitansi: null,
      placeholderFile: 'Pilih file...',
      loadingUpload: false,
      messages: '',
      isLoading: false,
    }
  },
  mounted() {
    if (this.metaAction === 'edit') {
      this.getAccommodation()
    }
  },
  methods: {
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    handleUploadKwitansi(event) {
      this.loadingUpload = true
      const file = event.target.files[0]

      if (file.size > 10 * 1024 * 1024) {
        this.$refs.resetLetter.click()
        this.letter = null
        this.model.letter = null
        this.placeholderFile = 'Pilih file disini...'
        this.$bvToast.toast(
          'Ukuran File Melebihi 10 MB, Silahkan Upload Kembali dengan ukuran file yang sesuai',
          {
            title: 'Tambah Akomodasi',
            variant: 'danger',
            solid: true,
          },
        )

        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('folder', this.$route.query.folder)
      formData.append('type', 'akomodasi')

      axios
        .post('/misc/upload-file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.loadingUpload = false
          this.model.document = res.data.filename
        })
        .catch(error => {
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        });
    },
    save() {
      const { note, cost, document } = this.model
      const data = {
        sppd_id: this.sppdId,
        note,
        total_cost: cost,
        document,
      }
      this.isLoading = true
      if (this.metaAction === 'store') {
        axios
          .post(`${this.config.api}`, data)
          .then(() => {
            this.isLoading = false
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya akomodasi berhasil ditambahkan',
              },
            })
          })
          .catch(error => {
            this.isLoading = false
            console.error(error)

            var message = "";
            if(typeof error.response.data.message === 'object'){
              message = error.response.data.message.errors[0].msg
            } else {
              message = error.response.data.message
            }
            this.makeToast('danger', "Terjadi Kesalahan", message)
          });
      } else {
        axios
          .put(`${this.config.api}/${this.sppdAccommodationId}`, data)
          .then(() => {
            this.isLoading = false
            this.goBack({
              query: {
                event: 'success',
                title: 'Berhasil',
                text: 'Biaya akomodasi berhasil diubah',
              },
            })
          })
          .catch((e) => {
            this.isLoading = false
            // console.error(e.message)
            if(typeof e.response.data.message === 'object'){
              this.messages = e.response.data.message.errors[0].msg
            } else {
              this.messages = e.response.data.message
            }
          })
      }
    },
    goBack({ query }) {
      if(this.beforeLink == 'detail-sppd'){
        this.$router.push({
          path: this.config.redirectDetail,
          query: { ...query, activeTab: this.actionTab },
        })
      } else {
        this.$router.push({
          path: this.config.redirect,
          query,
        })
      }
    },
    getAccommodation() {
      this.isLoading = true
      axios.get(`${this.config.api}/${this.sppdAccommodationId}`).then(res => {
        const { total_cost: cost, note, document, file } = res.data.data

        this.currentReceiptUrl = file

        this.model = { cost, note, document: document.split('/').at(-1) }
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      });
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style>
.kwitansi-filename {
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}
</style>
