<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Detail NPP">
        <b-row>
          <b-col cols="6" md="4" class="mb-2">
            <h6>No. Proposal</h6>
            <p class="card-text mb-25">
              {{ npp.proposal_number }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Kantor</h6>
            <p class="card-text mb-25">
              {{ npp.regional_name }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>No. Proposal Regional</h6>
            <p class="card-text mb-25">
              {{ npp.code_regional || '-' }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Jumlah Pegawai</h6>
            <p class="card-text mb-25">
              {{ npp.total_employees }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Tanggal Generate</h6>
            <p class="card-text mb-25">
              {{ npp.date | moment('DD/MM/YYYY') }}
            </p>
          </b-col>
        </b-row>
        <b-table
          :items="npp.npp_details"
          :fields="fields"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.item.name }} / {{ data.item.employee }}
          </template>
          <template #cell(kwitansi)="data">
            <b-button
              :href="data.item.folder_url"
              variant="info"
              target="_blank"
              size="sm"
            >
              Cek Kwitansi
            </b-button>
          </template>
        </b-table>
        <b-card-footer align="right">
          <b-button v-if="npp.status==0 && can('approve/pengajuan-npp')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 float-right"
              :disabled="isLoading"
              @click="confirmApprove"
            >
              <div v-if="isLoading">Approve <feather-icon icon="LoaderIcon" /></div>
              <div v-else>Approve</div>
          </b-button>
          <b-button v-if="npp.status==1 && npp.code_pusat == null && can('generate/pengajuan-npp')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1 float-right"
              :disabled="isLoading"
              @click="confirmGenerate"
            >
              <div v-if="isLoading">Generate <feather-icon icon="LoaderIcon" /></div>
              <div v-else>Generate</div>
          </b-button>
          <b-button variant="secondary" class="mr-1 float-right" @click="$router.back()">
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BTable, BButton, VBPopover } from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {
  components: { BRow, BCol, BCard, BCardFooter, BTable, BButton, VBPopover },
  directives: {
    Ripple,
    "b-popover": VBPopover
  },
  data() {
    return {
      config: {
        api: "/npps",
        redirect: 'pengajuan-npp-generated',
      },
      npp: {},
      isLoading: false,
      fields: [
        'no',
        { key: 'name', label: 'Nama / NIPPOS' },
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'kwitansi', label: 'Kwitansi' },
      ],
    }
  },
  mounted() {
    this.getNpp()
  },
  methods: {
    getNpp() {
      axios.get(`/npps/${this.$route.params.id}`).then(res => {
        this.npp = res.data.data
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    confirmApprove() {
      let _ = this;
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .put(`/npps/${this.$route.params.id}/approve`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Data berhasil di setujui',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getNpp()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    confirmGenerate() {
      let _ = this;
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk generate data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .put(`/npps/${this.$route.params.id}/approve`)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Data berhasil di generate',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getNpp()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style></style>
