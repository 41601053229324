<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-overlay
        variant="white"
        :show="isLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <img src="/logo-posind-dark-w-100.png" alt="logo-pos" />

            <!-- <h2 class="brand-text text-primary ml-1">
              WEB APP - SPPD
            </h2> -->
          </b-link>

          <!-- <b-card-title class="mb-1">
            Welcome to WEB APP - SPPD
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="handleSubmit(login)"
            >
              <!-- nippos -->
              <b-form-group label-for="nippos" label="NIPPOS">
                <ValidationProvider
                  #default="{ errors }"
                  name="NIPPOS"
                  rules="required"
                >
                  <b-form-input
                    id="nippos"
                    v-model="model.nippos"
                    name="login-nippos"
                    :state="errors.length > 0 ? false : null"
                    placeholder="202119483"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <ValidationProvider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="model.password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
              </b-form-group> -->

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="model.nippos == '' || model.password == ''"
              >
                Masuk
              </b-button>
            </b-form>
          </ValidationObserver>
        </b-card>
      </b-overlay>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      model: {
        nippos: '',
        password: '',
        isAdmin: true,
      },
      status: '',
      // validation rules
      required,
      isValid: true,
      isLoading: false,
      errors: {
        type: null,
        message: [],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    login() {
      this.isLoading = true;
      useJwt
        .login({
          nippos: this.model.nippos,
          password: this.model.password,
        })
        .then(response => {
          console.log(response.data.data.tokens)
          this.isLoading = false;
          // return;
          if (response.status == 200) {
            const userData = response.data.data
            userData.ability = [
              {
                action: 'manage',
                subject: 'all',
              },
            ]
            userData.role = 'admin'
            useJwt.setToken(response.data.data.token)
            // useJwt.setRefreshToken(response.data.data.tokens.refresh.token)
            localStorage.setItem('userData', JSON.stringify(userData))

            window.location = '/'
          } else {
            console.log(response.status)
          }
        })
        .catch(err => {
          let _ = this
          _.isLoading = false;
          console.log(err.response.data.error_code)
          _.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })

          if (err.response.data.error_code == 403) {
            setTimeout(() => {
              window.location = '/forgot-password'
            }, 3000)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
