<template>
  <b-row>
    <b-col v-if="can('read/manajemen-npp')" cols="12">
      <b-card title="Manajemen NPP">
        <b-button
          v-if="
            tabIndex === 0 &&
            can('tab-data/manajemen-npp') &&
            can('store/manajemen-npp') &&
            totalRows > 0 &&
            !loading
          "
          variant="primary"
          class="mb-1"
          @click="generateNpp()"
        >
          Generate
        </b-button>
        <b-row
          v-if="tabIndex === 1"
          class="mb-1 justify-content-between align-items-end"
        >
          <b-col md="4" lg="3" class="mb-1 mb-md-0">
            <label>Status</label>
            <b-form-select
              v-model="filterStatus"
              :options="filterStatusOptions"
            ></b-form-select>
          </b-col>
          <b-col md="6" lg="5">
            <b-input-group size="8">
              <b-form-input
                v-model="search"
                type="search"
                :placeholder="'Cari disini...'"
              />
              <b-input-group-append>
                <b-button :disabled="!search" @click="search = ''">
                  Hapus
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-tabs
          v-model="tabIndex"
          v-if="
            can('tab-data/manajemen-npp') && can('tab-generate/manajemen-npp')
          "
        >
          <b-tab lazy title="Data" v-if="can('tab-data/manajemen-npp')">
            <b-table
              ref="tableData"
              :items="getDataItems"
              :fields="dataFields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              striped
              hover
              show-empty
              :busy="busy"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
                  <div>Loading data, please wait...</div>
                </div>
              </template>
              <template #empty>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #emptyfiltered>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #cell(no)="data">
                {{ (currentPage - 1) * perPage + (data.index + 1) }}
              </template>
              <template #cell(name)="data">
                {{ data.item.nippos }} / {{ data.item.name }}
              </template>
              <template #cell(departure_date)="data">
                {{ data.item.departure_date | moment('DD/MM/YY') }}
              </template>
              <template #cell(return_date)="data">
                {{ data.item.return_date | moment('DD/MM/YY') }}
              </template>
              <template #cell(kwitansi)="data">
                <b-button
                  :href="data.item.folder_url"
                  target="_blank"
                  size="sm"
                  variant="info"
                >
                  Lihat Kwitansi
                </b-button>
              </template>
            </b-table>
          </b-tab>
          <b-tab
            lazy
            title="Generated"
            v-if="can('tab-generate/manajemen-npp')"
          >
            <b-table
              ref="tableGenerated"
              :items="getGeneratedItems"
              :fields="generatedFields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              striped
              hover
              show-empty
              :busy="busy"
            >
              <template #table-busy>
                <div class="text-center my-3">
                  <!-- <b-spinner small type="grow" label="Loading..."></b-spinner> -->
                  <div>Loading data, please wait...</div>
                </div>
              </template>
              <template #empty>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #emptyfiltered>
                <div class="text-center my-2">Data tidak tersedia</div>
              </template>
              <template #cell(no)="data">
                {{ (currentPage - 1) * perPage + (data.index + 1) }}
              </template>
              <template #cell(date)="data">
                {{ data.item.date | moment('DD/MM/YY') }}
              </template>
              <template #cell(action)="data">
                <div style="max-width: 100px;">
                  <b-button
                    v-if="can('show/manajemen-npp')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Detail'"
                    variant="info"
                    size="sm"
                    class="btn-icon float-sm-left mr-1 mb-1"
                    @click="gotoDetailPage(data.item.id)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    v-if="can('export/manajemen-npp')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Download Excel'"
                    variant="secondary"
                    size="sm"
                    class="btn-icon float-sm-left mr-1 mb-1"
                    :disabled="loading"
                    @click="exportData(data.item.id, data.item.proposal_number)"
                  >
                    <!-- <feather-icon icon="DownloadIcon" /> -->
                     <b-icon-file-earmark-spreadsheet></b-icon-file-earmark-spreadsheet>
                  </b-button>
                  <b-button
                    v-if="can('pdf/manajemen-npp')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Download PDF'"
                    variant="secondary"
                    size="sm"
                    class="btn-icon float-sm-left"
                    :disabled="loading"
                    @click="downloadPdf(data.item.id, data.item.proposal_number)"
                  >
                  <!-- <b-icon icon="file-earmark-pdf-fill"></b-icon> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                      <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"/>
                    </svg>
                    <!-- <feather-icon icon="DownloadIcon" /> -->
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-tab>
          <div class="d-flex justify-content-between flex-wrap mt-1">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
                @change="refreshTable"
              />
            </b-form-group>

            <div>
              Menampilkan {{ from }} hingga {{ to }} dari {{ totalRows }} entri
            </div>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-tabs>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BIcon,
  BIconFileEarmarkSpreadsheet,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BPagination,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import debounce from 'debounce'

export default {
  name: 'ManajemenNPP',
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BIcon,
    BIconFileEarmarkSpreadsheet,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BPagination,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      config: {
        api: '/npps',
      },
      tabIndex: 0,
      dataFields: [
        'no',
        { key: 'name', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'company', label: 'Kantor Asal' },
        { key: 'name_subdirektorat', label: 'Sub Direktorat' },
        { key: 'name_regional', label: 'Regional' },
        { key: 'destination_office', label: 'Tujuan' },
        { key: 'departure_date', label: 'Tanggal Berangkat' },
        { key: 'return_date', label: 'Tanggal Kembali' },
        { key: 'kwitansi', label: 'Kwitansi' },
      ],
      generatedFields: [
        'no',
        { key: 'proposal_number', label: 'No Proposal' },
        { key: 'subdirektorat', label: 'Sub Direktorat' },
        { key: 'regional_name', label: 'regional' },
        // { key: 'regional_name', label: 'Kantor' },
        // { key: 'code_regional', label: 'No Proposal Reg' },
        { key: 'total_employees', label: 'Jumlah Pegawai' },
        { key: 'date', label: 'Tanggal Generate' },
        { key: 'action', label: 'Aksi', stickyColumn: true, class: 'sticky-column pr-1' },
      ],
      search: '',
      filterStatus: 1,
      filterStatusOptions: [
        {
          value: 0,
          text: 'Waiting Approval',
        },
        {
          value: 1,
          text: 'Approved',
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 10,
      from: 1,
      to: 10,
      pageOptions: [1, 10, 25, 50, 100],
      loading: false,
      busy: false,
      busy: false,
    }
  },
  watch: {
    filterStatus() {
      this.refreshTable()
    },
    search: debounce(function () {
      this.refreshTable()
    }, 500),
  },
  mounted() {
    const { query } = this.$route
    if (Object.keys(query).length) {
      if (query.event) {
        this.makeToast(query.event, query.title, query.text)
      }
      this.$router.replace({ query: null })
    }
  },
  methods: {
    refreshTable() {
      if (this.tabIndex === 0) {
        this.$refs.tableData.refresh()
      } else {
        this.$refs.tableGenerated.refresh()
      }
    },
    getDataItems(ctx, callback) {
      this.loading = true
      const url = `${this.config.api}/sppd?page=${this.currentPage}&per_page=${this.perPage}`
      this.busy = true;

      axios
        .get(url)
        .then(res => {
          const consume = res.data.data
          this.perPage = consume.per_page
          this.currentPage = consume.current_page
          this.totalRows = consume.total
          this.from = consume.from
          this.to = consume.to
          this.loading = false
          callback(consume.data)
        })
        .catch(error => {
          this.loading = false
          console.error(error)

          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
        .finally(() => {
          this.busy = false;
        });
    },
    getGeneratedItems(ctx, callback) {
      const url = `${this.config.api}?page=${this.currentPage}&per_page=${this.perPage}&key=${this.search}&status=${this.filterStatus}`
      this.busy = true;
      
      axios.get(url).then(res => {
        const consume = res.data.data
        this.perPage = consume.per_page
        this.currentPage = consume.current_page
        this.totalRows = consume.total
        callback(consume.data || [])
      })
      .catch(error => {
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
      .finally(() => {
        this.busy = false;
      });
    },
    generateNpp() {
      this.$router.push({ name: 'manajemen-npp-generate', params: { actionTab: 'pengeluaran-riil' }, })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    gotoDetailPage(id) {
      this.$router.push({
        name: 'manajemen-npp-detail',
        params: { id },
      })
    },
    exportData(id, proposalNumber) {
      this.loading = true
      axios({
        url: `${this.config.api}/${id}/export`,
        method: 'GET',
        responseType: 'blob',
      })
        .then(res => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${proposalNumber}-${id}.xlsx`)
          document.body.appendChild(link)
          this.loading = false
          link.click()
          document.body.removeChild(link)
        })
        .catch(error => {
          this.loading = false
          console.error(error)
          var message = "";
          if(typeof error.response.data.message === 'object'){
            message = error.response.data.message.errors[0].msg
          } else {
            message = error.response.data.message
          }
          this.makeToast('danger', "Terjadi Kesalahan", message)
        })
    },
    downloadPdf(id, proposalNumber) {
      this.loading = true
      axios({
        url: `${this.config.api}/${id}/pdf`,
        method: 'GET',
        responseType: 'blob',
      })
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/pdf',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${proposalNumber}-${id}.pdf`)
        document.body.appendChild(link)
        this.loading = false
        link.click()
        document.body.removeChild(link)
      })
      .catch(error => {
        this.loading = false
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
      // window.open('https://pdfobject.com/pdf/sample.pdf', '_blank');
    }
  },
}
</script>

<style></style>
